.main {
    padding: 10px;
    min-height: 360px;

}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.topBar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    width: 100%;
    background-color: #fafafa;
    border-bottom: 1px solid #0000000f;
    padding: 0 18px;

    .itemName {
      display: flex;
      flex-shrink: 0;
      flex-grow: 2;
      width: 200px;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      padding-left: 16px;
      height: 56px;
    }

    .editTime {
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      width: 200px;
    }

    .wordCount {
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      width: 128px;
    }

    .operation {
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      width: 60px;
    }

    .sep {
      top: 17px;
      left: 0;
      height: 22px;
      width: 1px;
      margin-right: 16px;
      background-color: #0000000f;
    }
  }

  